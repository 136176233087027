import { Typography, Box, Grid } from "@mui/material";
import { Container } from "@mui/system";


const ChildWeightLoss = () => {
    return (
        <div>
            <Container maxWidth='lg'>
                {/*------------ top gap between sections =4 ---------- */}
                <Box py={4}></Box>

                {/*------------ Sections ---------- */}
                <Box
                    sx={{
                        backgroundColor: 'red',
                        minHeight: '60vh',
                        padding: '40px'
                    }}>

                    {/*------------ Grid comes here ---------- */}

                    <Grid container>
                        <Grid
                            item
                            xs={2}
                            sx={{ border: '2 solid white' }}


                        >
                            <Typography
                                variant="h6"
                            >
                                Smart NextGen
                            </Typography>
                        </Grid>

                    </Grid>

                </Box>

                {/*------------ gap between sections ---------- */}
                <Box py={8}></Box>

                {/*------------ Sections ---------- */}
                <Box
                    sx={{
                        backgroundColor: 'blue',
                        minHeight: '60vh',
                        padding: '40px'
                    }}>

                    {/*------------ Grid comes here ---------- */}
                    <Box>
                        <Typography>Smart NextGen</Typography>
                    </Box>


                </Box>

                {/*------------ gap between sections ---------- */}
                <Box py={8}></Box>

            </Container>
        </div>
    );
}

export default ChildWeightLoss;


import { Container, Grid, Box, Button, Typography } from '@mui/material'
// import { useTheme } from '@mui/material'
// import { grey } from '@mui/material/colors'
import PlaceIcon from '@mui/icons-material/Place'
import EmailIcon from '@mui/icons-material/Email'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'




const Footer = () => {

    // const theme = useTheme();

    // const bgColors = { "Default": "#2c3878" }
    // const footercolor1 = '#fbfbf2';
    // const footercolor2 = '#c7d6d5';
    const footercolor2 = 'black';
    // const footertextcolor1 = 'black';
    // const xsorange = '#ff5e20';

    return (
        <footer>
            <Box
                py={{ xs: 3, sm: 2 }}
                bgcolor='black'>

                <Container maxWidth="lg">
                    <Box textAlign='center'>
                        <Button variant='text' onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }}>
                            Back to top
                        </Button>
                    </Box>

                </Container>
            </Box>

            <Box
                px={{ xs: 3, sm: 10 }}
                py={{ xs: 5, sm: 10 }}
                bgcolor={footercolor2}>
                <Container maxWidth='lg'>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={3}>

                        </Grid>

                        <Grid item xs={12} sm={3} >

                        </Grid>




                        {/* -----------Contact------------ */}
                        <Grid item xs={12} sm={12} md={6} sx={
                            {
                                display: 'flex',
                                flexDirection: 'column',

                                alignItems: 'flex-end'

                            }
                        }>

                            <Box color='white'>
                                <Box pb={3} >
                                    <Typography variant='h6' borderBottom={1}>
                                        Contact
                                    </Typography>
                                </Box>

                                <Box color='white' sx={{ display: 'flex' }}>
                                    <EmailIcon />
                                    <Box ml={2}>
                                        <Typography>info@yjsmicro.com</Typography>
                                    </Box>
                                </Box>
                                <Box color='white' sx={{ display: 'flex' }} pt={2}>
                                    <PhoneEnabledIcon />
                                    <Box ml={2}>
                                        <Typography>+91 739 755 7399</Typography>
                                    </Box>
                                </Box>

                                <Box color='white' sx={{ display: 'flex' }} pt={2}>
                                    <PlaceIcon />
                                    <Box ml={2}>
                                        <Typography>Shri Lalitha Nilayam,</Typography>
                                        <Typography>Plot N0. - 4, St. Joseph Nagar</Typography>
                                        <Typography>Village - Molachur, Town - Sunguvarchatram</Typography>
                                        <Typography>City - Sriperumbudur, District - Kanchipuram</Typography>
                                        <Typography>State - Tamil nadu, India, Pin - 602106</Typography>
                                    </Box>
                                </Box>

                            </Box>

                        </Grid>

                    </Grid>
                    <Box color='white' textAlign="center" pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 0 }}>
                        Copyright &copy; 2021-{new Date().getFullYear()} YJS Micro Electronics Pty Ltd <br /> All rights reserved.
                    </Box>
                </Container>
            </Box>

        </footer >
    );
}

export default Footer;
import { Typography, Box, Container } from "@mui/material";

const Programs = () => {
    return (
        <div>
            <Box bgcolor='#6a994e' >
                <Container maxWidth='lg'>
                    <Box bgcolor='#fff'>
                        <Typography>My Parents</Typography>
                    </Box>
                </Container>
            </Box>
        </div>
    );
}

export default Programs;
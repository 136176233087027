
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material'
import Layout from './layouts/Layout';
import Home from './pages/Home'
import Schools from './pages/Schools'
import BookDemo from './pages/BookDemo'
import About from './pages/About'
import Contact from './pages/Contact'
import Programs from './pages/Programs';
import ChildWeightGain from './pages/ChildWeightGain';
import ChildWeightLoss from './pages/ChildWeightLoss';


const pages = [
  {
    id: 1,
    name: 'Home',
    path: '/',
    pg_element: <Home />,
    submenus: []
  },
  {
    id: 2,
    name: 'Schools',
    path: '/schools',
    pg_element: <Schools />,
    submenus: []
  },
  {
    id: 3,
    name: 'Programs',
    path: '/programs',
    pg_element: <Programs />,
    submenus: [
      {
        name: 'ChildWeightLoss',
        path: '/child-weight-loss',
        pg_element: <ChildWeightLoss />
      },
      {
        name: 'ChildWeightGain',
        path: '/child-weight-gain',
        pg_element: <ChildWeightGain />
      }
    ]

  },
  {
    id: 4,
    name: 'About',
    path: '/about',
    pg_element: <About />,
    submenus: []
  },
  {
    id: 5,
    name: 'BookDemo',
    path: '/bookdemo',
    pg_element: <BookDemo />,
    submenus: []
  },
  {
    id: 6,
    name: 'Contact',
    path: '/contact',
    pg_element: <Contact />,
    submenus: []
  }
]

const routeComponents = pages.map((pagelist) => (
  pagelist.name === 'Programs' ? (pagelist.submenus.map((subMenuList) => (<Route key={subMenuList.name} exact path={subMenuList.path} element={subMenuList.pg_element}></Route>)))
    : (<Route key={pagelist.name} exact path={pagelist.path} element={pagelist.pg_element}></Route>)

))

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      // main: '#2c3878',
      main: '#fff',
    },
    secondary: {
      main: '#f50057',
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightRegular: 500,
    fontWeightLight: 400
  },
  // components: {
  //   MuiList: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: '#2c3878',
  //         color: 'white'
  //       }
  //     }
  //   }
  // }
})


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Routes>
          {routeComponents}
          {/* <Route path='/' element={<Home />} /> */}
          {/* <Route path='/schools' element={<Schools />} />
          <Route path='/parents' element={<Parents />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} /> */}
        </Routes>
      </Layout>
    </ThemeProvider>
  );
}

export default App;

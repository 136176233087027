/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import WebNavbar from '../components/ui/WebNavbar'
import { useTheme } from '@mui/material'
import Footer from '../components/ui/Footer';




export default function Layout({ children }) {

    const theme = useTheme();

    return <div>

        <div css={css({
            display: 'flex',
            flexDirection: 'column',
            height: '100vh'

        })}>
            <WebNavbar />

            <div css={css({
                flexGrow: 1
            })}>


                <div css={css({
                    minHeight: 56 + 20,
                    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: { minHeight: 48 + 20 },
                    [theme.breakpoints.up('sm')]: { minHeight: 64 + 20 }
                })}></div>


                {children}
                <Footer></Footer>
            </div>

        </div>




    </div >
}
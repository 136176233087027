
import { AppBar, Toolbar, Typography, Box, IconButton, useMediaQuery, useTheme, Button, Menu, MenuItem } from "@mui/material";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Link } from "react-router-dom";
import { useState } from "react";
import DrawerNavbar from './DrawerNavbar'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import SchoolIcon from '@mui/icons-material/School'
import DiversityIcon from '@mui/icons-material/Diversity1'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Face2Icon from '@mui/icons-material/Face2'
import Face3Icon from '@mui/icons-material/Face3'



const pages = [
    {
        id: 1,
        name: 'Home',
        path: '/',
        icon: <HomeIcon />,
        submenu: []
    },
    {
        id: 2,
        name: 'Schools',
        path: '/schools',
        icon: <SchoolIcon />,
        submenu: []
    },
    {
        id: 3,
        name: 'Programs',
        path: '/programs',
        icon: <DiversityIcon />,
        submenu: [
            {
                name: 'ChildWeightLoss',
                path: '/child-weight-loss',
                icon: <Face2Icon />,
            },
            {
                name: 'ChildWeightGain',
                path: '/child-weight-gain',
                icon: <Face3Icon />,
            }
        ]
    },
    {
        id: 4,
        name: 'About',
        path: '/about',
        icon: <PeopleAltIcon />,
        submenu: []
    },
    {
        id: 5,
        name: 'BookDemo',
        path: '/bookdemo',
        icon: <CalendarMonthIcon />,
        submenu: []
    },
    {
        id: 6,
        name: 'Contact',
        path: '/contact',
        icon: <ImportContactsIcon />,
        submenu: []
    }
]




const WebNavbar = () => {

    const menucolor1 = '#ff5e20';
    const menucolor2 = 'black';
    const xsorange = '#ff5e20'

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    const [menuname, setMenuname] = useState('Home')

    const [openDrawer, setOpenDrawer] = useState(false)

    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    // const shineborder = css({
    //     border: '2px solid #bdbdbd'
    // })

    // const hoverborder = css({

    //     textDecoration: 'none',
    //     border: '2px solid transparent',
    //     '&:hover': shineborder
    // })


    // const menuComponents = pages.map(({ name, path, id }) =>
    // (<Typography key={id} style={{ color: menuname === name ? menucolor1 : menucolor2 }} mr={1} css={hoverborder} component={Link} to={path} onClick={() => {
    //     setMenuname(name)
    // }}>
    //     {name}
    // </Typography>)
    // )

    return (
        <AppBar elevation={0} color="primary" style={{ zIndex: theme.zIndex.drawer + 1 }} position="fixed" >
            <Toolbar >

                <Typography
                    fontSize='3rem'
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        display: { xs: 'flex', md: 'flex' },
                        fontFamily: 'Sonsie One',
                        textDecoration: 'none',
                    }}

                    style={{ color: xsorange }}
                >
                    X
                </Typography>

                <Typography
                    css={css({
                        flexGrow: 2
                    })}
                    variant="body2"
                    fontSize='1.25rem'
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        mr: 5,
                        display: { xs: 'flex', md: 'flex' },
                        fontFamily: 'Sonsie One',
                        textDecoration: 'none',
                        color: 'inherit'
                    }}
                >
                    traSenses
                </Typography>

                {
                    isMatch ? (
                        <>
                            <IconButton color="inherit" onClick={() => {
                                if (open) {
                                    setAnchorEl(null);
                                }
                                setOpenDrawer(!openDrawer);
                            }}>
                                <MenuIcon />
                            </IconButton>
                            <DrawerNavbar drawerState={openDrawer} setDrawerState={setOpenDrawer} menuPages={pages} setMenunameState={setMenuname} />
                        </>
                    ) : (
                        <>

                            {/* {menuComponents} */}

                            <Button size="large" variant="text" sx={{ textTransform: 'none', marginRight: '12px', color: menuname === 'Home' ? menucolor1 : menucolor2 }} component={Link} to={'/'} onClick={() => {
                                setMenuname('Home')
                            }}>
                                Home
                            </Button>

                            <div>
                                <Button id="basic-button"
                                    variant="text"
                                    size="large"
                                    endIcon={<ArrowDropDownIcon />}
                                    sx={{ textTransform: 'none', marginRight: '12px', color: menuname === 'Programs' ? menucolor1 : menucolor2 }}
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}>
                                    In-School Programs
                                </Button>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}


                                >
                                    <MenuItem component={Link} to={'/child-weight-loss'} onClick={() => {
                                        setMenuname("Programs");
                                        handleClose();
                                    }}>
                                        <Typography variant="subtitle2">Smart NextGen</Typography>
                                    </MenuItem>

                                    <MenuItem component={Link} to={'/child-weight-gain'} onClick={() => {
                                        setMenuname("Programs");
                                        handleClose();
                                    }}>
                                        <Typography variant="subtitle2">Personality Builder</Typography>
                                    </MenuItem>

                                </Menu>

                            </div>

                            {/* <Button size="large" variant="text" sx={{ textTransform: 'none', marginRight: '12px', color: menuname === 'Schools' ? menucolor1 : menucolor2 }} mr={5} component={Link} to={'/schools'} onClick={() => {
                                setMenuname('Schools')
                            }}>
                                Schools
                            </Button> */}

                            <Button size="large" variant="text" sx={{ textTransform: 'none', marginRight: '12px', color: menuname === 'About' ? menucolor1 : menucolor2 }} mr={5} component={Link} to={'/about'} onClick={() => {
                                setMenuname('About')
                            }}>
                                About
                            </Button>

                            <Button size="large" variant="text" sx={{ textTransform: 'none', marginRight: '12px', color: menuname === 'Contact' ? menucolor1 : menucolor2 }} mr={5} component={Link} to={'/contact'} onClick={() => {
                                setMenuname('Contact')
                            }}>
                                Contact
                            </Button>



                            <Button size="large" variant="contained" disableElevation

                                sx={{ textTransform: 'none', padding: '10px 20px 10px 20px', backgroundColor: '#5070FF', color: 'white', borderRadius: '8px', '&:hover': { backgroundColor: '#5070FF', color: 'white' } }}
                                onClick={() => {
                                    setMenuname('BookDemo')
                                }}>
                                Book Demo
                            </Button>
                            <Box sx={{ display: 'flex', flexGrow: '1' }}></Box>


                        </>
                    )
                }



            </Toolbar>
        </AppBar>
    );
}

export default WebNavbar;
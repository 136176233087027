
import { Collapse, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useNavigate } from "react-router-dom"
import CloseIcon from '@mui/icons-material/Close'
import Face2Icon from '@mui/icons-material/Face2'
import Face3Icon from '@mui/icons-material/Face3'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useState } from 'react'




const DrawerNavbar = (props) => {

    const [open, setOpen] = useState(false);
    const handleClick = () => { setOpen(!open) }
    const navigate = useNavigate();

    const pages = props.menuPages;

    // const menuComponents = pages.map((list) => (
    //     <ListItem key={list.id} sx={{ justifyContent: 'center' }}>

    //         <ListItemButton onClick={() => {
    //             navigate(list.path);
    //             props.setDrawerState(false);
    //         }}

    //             sx={
    //                 {
    //                     color: 'black',
    //                     width: '300px', flexGrow: 0,
    //                     '&:hover': { color: 'primary.main', backgroundColor: 'rgba()' }
    //                 }
    //             }>

    //             <ListItemIcon sx={{ color: 'inherit' }}>
    //                 {list.icon}
    //             </ListItemIcon>
    //             <ListItemText
    //                 primaryTypographyProps={{ variant: 'h5', color: 'inherit' }}>
    //                 {list.name}
    //             </ListItemText>
    //         </ListItemButton>
    //     </ListItem>
    // ));

    return (
        <div>
            <Drawer
                variant='temporary'
                anchor='right'
                open={props.drawerState}
                onClose={() => props.setDrawerState(false)}
                PaperProps={{
                    sx: {

                        height: '100%',
                        justifyContent: 'center',
                        width: "100%",
                        backgroundColor: "#ff5e20"
                    }
                }}>

                <List>
                    <ListItem sx={{ justifyContent: 'flex-end' }}>
                        <ListItemButton onClick={() => props.setDrawerState(false)} sx={{ flexGrow: 0 }}>
                            <ListItemIcon >
                                <CloseIcon fontSize='large' />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    <Divider variant='middle' />


                    {/* {menuComponents} */}

                    <ListItem sx={{ justifyContent: 'center' }}>

                        <ListItemButton onClick={() => {
                            navigate(pages[0].path);
                            props.setMenunameState(pages[0].name);
                            props.setDrawerState(false);
                        }}

                            sx={
                                {
                                    color: 'black',
                                    width: '300px', flexGrow: 0,
                                    '&:hover': { color: 'primary.main', backgroundColor: 'rgba()' }
                                }
                            }>

                            <ListItemIcon sx={{ color: 'inherit' }}>
                                {pages[0].icon}
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6', color: 'inherit' }}>
                                {pages[0].name}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem sx={{ justifyContent: 'center' }}>

                        <ListItemButton onClick={() => {
                            navigate(pages[1].path);
                            props.setMenunameState(pages[1].name)
                            props.setDrawerState(false);
                        }}

                            sx={
                                {
                                    color: 'black',
                                    width: '300px', flexGrow: 0,
                                    '&:hover': { color: 'primary.main', backgroundColor: 'rgba()' }
                                }
                            }>

                            <ListItemIcon sx={{ color: 'inherit' }}>
                                {pages[1].icon}
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6', color: 'inherit' }}>
                                {pages[1].name}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem sx={{ justifyContent: 'center' }}>

                        <ListItemButton onClick={() => {
                            // navigate(pages[2].path);
                            // props.setDrawerState(false);
                            handleClick();
                        }}

                            sx={
                                {
                                    color: 'black',
                                    width: '300px', flexGrow: 0,
                                    '&:hover': { color: 'primary.main', backgroundColor: 'rgba()' }
                                }
                            }>

                            <ListItemIcon sx={{ color: 'inherit' }}>
                                {pages[2].icon}
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6', color: 'inherit' }}>
                                {pages[2].name}
                            </ListItemText>
                        </ListItemButton>
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>


                    <Collapse in={open} timeout="auto" unmountOnExit >

                        <List>
                            <ListItem sx={{ justifyContent: 'center' }}>
                                <ListItemButton onClick={() => {
                                    navigate(pages[2].submenu[0].path);
                                    props.setMenunameState(pages[2].name);
                                    props.setDrawerState(false);
                                }}
                                    sx={
                                        {
                                            marginLeft: '100px',
                                            color: 'black',
                                            width: '300px', flexGrow: 0,
                                            '&:hover': { color: 'primary.main', backgroundColor: 'rgba()' }
                                        }
                                    }>
                                    <Face2Icon sx={
                                        { color: 'inherit', marginRight: '10px' }
                                    }>
                                        {pages[2].icon}
                                    </Face2Icon>
                                    <ListItemText primaryTypographyProps={{ variant: 'subtitle1', color: 'inherit' }}>
                                        Child Weight-Loss
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>

                            <ListItem sx={{ justifyContent: 'center' }}>
                                <ListItemButton onClick={() => {
                                    navigate(pages[2].submenu[1].path);
                                    props.setMenunameState(pages[2].name);
                                    props.setDrawerState(false);
                                }}
                                    sx={
                                        {
                                            marginLeft: '100px',
                                            color: 'black',
                                            width: '300px', flexGrow: 0,
                                            '&:hover': { color: 'primary.main', backgroundColor: 'rgba()' }
                                        }
                                    }>
                                    <Face3Icon sx={{ color: 'inherit', marginRight: '10px' }}>
                                        {pages[2].icon}
                                    </Face3Icon>
                                    <ListItemText primaryTypographyProps={{ variant: 'subtitle1', color: 'inherit' }}>
                                        Child Nutrition & Fitness
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>


                    <ListItem sx={{ justifyContent: 'center' }}>

                        <ListItemButton onClick={() => {
                            navigate(pages[3].path);
                            props.setMenunameState(pages[3].name);
                            props.setDrawerState(false);
                        }}

                            sx={
                                {
                                    color: 'black',
                                    width: '300px', flexGrow: 0,
                                    '&:hover': { color: 'primary.main', backgroundColor: 'rgba()' }
                                }
                            }>

                            <ListItemIcon sx={{ color: 'inherit' }}>
                                {pages[3].icon}
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6', color: 'inherit' }}>
                                {pages[3].name}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>


                    <ListItem sx={{ justifyContent: 'center' }}>

                        <ListItemButton onClick={() => {
                            navigate(pages[4].path);
                            props.setMenunameState(pages[4].name);
                            props.setDrawerState(false);
                        }}

                            sx={
                                {
                                    color: 'black',
                                    width: '300px', flexGrow: 0,
                                    '&:hover': { color: 'primary.main', backgroundColor: 'rgba()' }
                                }
                            }>

                            <ListItemIcon sx={{ color: 'inherit' }}>
                                {pages[4].icon}
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6', color: 'inherit' }}>
                                {pages[4].name}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>


                    <ListItem sx={{ justifyContent: 'center' }}>

                        <ListItemButton onClick={() => {
                            navigate(pages[5].path);
                            props.setMenunameState(pages[5].name);
                            props.setDrawerState(false);
                        }}

                            sx={
                                {
                                    color: 'black',
                                    width: '300px', flexGrow: 0,
                                    '&:hover': { color: 'primary.main', backgroundColor: 'rgba()' }
                                }
                            }>

                            <ListItemIcon sx={{ color: 'inherit' }}>
                                {pages[5].icon}
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6', color: 'inherit' }}>
                                {pages[5].name}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

















                </List>
            </Drawer>
        </div>
    );
}

export default DrawerNavbar;
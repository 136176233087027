import { Typography, Box, Grid, Button, Avatar, Divider } from "@mui/material";
import { Container } from "@mui/system";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { motion } from 'framer-motion'


const Home = () => {
    return (
        <div>
            <Container maxWidth='lg'>
                {/*------------ top gap between sections =4 ---------- */}
                <Box py={2}></Box>

                {/*------------ Sections ---------- */}
                <Box display='flex' alignItems='center'
                    sx={{
                        // backgroundColor: 'red',
                        minHeight: '60vh',
                        padding: '40px'
                    }}>

                    {/*------------ Grid comes here ---------- */}

                    <Grid container spacing={6}>
                        {/* ----------------------------- */}

                        <Grid display='flex' justifyContent='center' item sm={12} md={6}>

                            <Box bgcolor='yellow'
                                width='275px'
                                height='375px'

                                component={motion.div}

                                initial={
                                    {
                                        // x: -50,
                                        opacity: 1
                                    }
                                }
                                animate={
                                    {
                                        // x: 50,
                                        opacity: 0,
                                        rotateY: 90
                                        // scale: 0.5

                                    }
                                }
                                transition={
                                    {
                                        delay: 1,
                                        duration: 1
                                    }
                                }


                                pt={5}
                                sx={{ flexGrow: 0 }}
                                css={css({
                                    position: 'relative',
                                    clipPath: 'polygon(10% 0%, 100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%)',
                                    borderRadius: '0px 40px 0px 20px'
                                })}
                            >
                                <img height='100%' width='100%' css={css({ objectFit: 'fill' })} src={"images/overweight_girl_1.png"} alt="" />
                            </Box>

                            <Box bgcolor='yellow'
                                width='275px'
                                height='375px'

                                component={motion.div}

                                initial={
                                    {

                                        opacity: 0,
                                        // scale: 0,
                                        rotateY: 90,
                                        // x: -50,


                                    }
                                }
                                animate={
                                    {
                                        // x: 50,
                                        // scale: 1,
                                        rotateY: [0, -90, 0],
                                        opacity: [0, 0, 0.25, 1],
                                        // rotateZ: -720
                                    }
                                }
                                transition={
                                    {
                                        delay: 1,
                                        duration: 1
                                    }
                                }


                                pt={5}
                                sx={{ flexGrow: 0 }}
                                css={css({
                                    position: 'absolute',
                                    clipPath: 'polygon(10% 0%, 100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%)',
                                    borderRadius: '0px 40px 0px 20px'
                                })}
                            >
                                <img height='100%' width='100%' css={css({ objectFit: 'fill' })} src={"images/HappyGirl.png"} alt="" />
                            </Box>
                        </Grid>

                        {/* ----------------- */}
                        <Grid item
                            sm={12}
                            md={6}
                            sx={{ border: '2 solid red' }}
                        >
                            <Box alignContent='center'>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        // fontSize: { xs: '1.8em', sm: '2em', md: '2.4em', xl: '3em' },
                                        fontWeight: '700',
                                        // lineHeight: '1.4em'
                                    }}
                                    mb={4}
                                >
                                    40 Weeks - InSchool Program for Healthier and brighter kids.
                                </Typography>



                                <Typography
                                    variant="body1"
                                    mb={4}
                                >
                                    No extra effort required by parents. Evidence based positive outcome with as little as 20 min session a day.
                                </Typography>



                                <Button variant="contained"
                                    sx={{
                                        textTransform: 'none',
                                        padding: '14px 20px 14px 20px',
                                        backgroundColor: '#5070FF',
                                        color: 'white',
                                        borderRadius: '8px',
                                        '&:hover': {
                                            backgroundColor: '#5070FF',
                                            color: 'white'
                                        }
                                    }}
                                >
                                    Available  in  my  School?
                                </Button>
                            </Box>
                        </Grid>

                        {/* ----------------------- */}

                    </Grid>

                </Box>
            </Container>

            {/*------------ gap between sections ---------- */}
            <Box py={4}></Box>
            {/*------------ gap between sections ---------- */}

            <Box
            // bgcolor='#f9f9f9'
            >
                <Container>
                    <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        sx={{
                            // backgroundColor: '#5070FF',
                            minHeight: '60vh',
                            padding: '40px'
                        }}
                    >
                        <Typography
                            pt={4}
                            variant="h4"
                            // color='white'
                            sx={{

                                fontWeight: '600',
                                // lineHeight: '1.4em'
                            }}

                        >
                            Why you should choose XtraSenses
                        </Typography>


                        <Grid container spacing={12} mt={1} mb={4}>
                            <Grid item md={12}>
                                <Box bgcolor='#0e5ed7' py={4} px={4} sx={{
                                    borderRadius: '60px',
                                    // border: '10px solid #0e5ed7'
                                }}>
                                    <Grid container spacing={12}>
                                        <Grid item md={6} >
                                            <Box px={4} py={4}

                                                component={motion.div}
                                                initial={{
                                                    x: -500,

                                                }}
                                                animate={{
                                                    x: 0
                                                }}
                                            >
                                                <Box pb={3} display='flex' Box color='white'>
                                                    <Typography variant="h4" mr={4}>01.</Typography>
                                                    <Box >
                                                        <Typography pb={2} variant="h5" sx={{ fontWeight: '600' }}>Evidence based scientific program</Typography>
                                                        <Typography variant="body1" color='#dde8f9' >XtraSenses logs every significant data of our program as deliverd to the child
                                                            as well as the physiological response to it.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item md={6} >
                                            <Box bgcolor='#fff' borderRadius='60px' overflow='hidden'
                                                border='4px solid #000'
                                            >
                                                <img alt='heart-rate' src="/images/heart-rate.jpg"
                                                    css={{
                                                        maxWidth: '100%',
                                                        height: 'auto',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>



                            <Grid item md={12}>
                                <Box bgcolor='#f5f5f5' py={4} px={4}
                                    sx={{
                                        borderRadius: '60px',
                                        // border: '10px solid #0e5ed7'
                                    }}
                                >
                                    <Grid container md={12} spacing={12}>
                                        <Grid item md={6} display='flex' alignItems='center'>
                                            <Box bgcolor='grey' borderRadius='60px' overflow='hidden'>
                                                <img alt='pediatrician-bmi' src="/images/Obesity-feature.webp"
                                                    css={{
                                                        maxWidth: '100%',
                                                        height: 'auto',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item md={6}>
                                            <Box px={4} py={4}
                                                // sx={{
                                                //     borderRadius: '60px',
                                                //     border: '10px solid #0e5ed7'
                                                // }}
                                                component={motion.div}
                                                initial={{
                                                    x: 500,

                                                }}
                                                animate={{
                                                    x: 0
                                                }}
                                            >
                                                <Box pb={3} display='flex' >
                                                    <Typography variant="h4" mr={4}>02.</Typography>
                                                    <Box>
                                                        <Typography pb={2} variant="h5" sx={{ fontWeight: '600' }}>Program led by doctors</Typography>
                                                        <Typography color='GrayText'>
                                                            XtraSenses medical team led by Pediatricians personalize the program to every individual child's needs.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item md={12}>
                                <Box bgcolor='#0e5ed7' py={4} px={4} sx={{
                                    borderRadius: '60px',
                                    // border: '10px solid #0e5ed7'
                                }}>
                                    <Grid container spacing={12}>
                                        <Grid item md={6}>
                                            <Box py={4} sx={{
                                                borderRadius: '60px',
                                                border: '10px solid #0e5ed7'
                                            }}
                                                component={motion.div}
                                                initial={{
                                                    x: -500,

                                                }}
                                                animate={{
                                                    x: 0
                                                }}
                                            >
                                                <Box pb={3} display='flex' color='white'>
                                                    <Typography variant="h4" mr={4}>03.</Typography>
                                                    <Box>
                                                        <Typography pb={2} variant="h5" sx={{ fontWeight: '600' }}>Well-Trained, Experienced coaches</Typography>
                                                        <Typography variant="body1" color='#dde8f9'>
                                                            Our experienced coaches are trained to make the activity hours fun and safe for the kids.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item md={6} display='flex' alignItems='center'>
                                            <Box bgcolor='grey' borderRadius='60px' overflow='hidden'>
                                                <img alt='trained-coaches' src="/images/Trained-coaches.png"
                                                    css={{
                                                        maxWidth: '100%',
                                                        height: 'auto',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </Box>

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>


                            <Grid item md={12}>
                                <Box bgcolor='#f5f5f5' py={4} px={4} sx={{
                                    borderRadius: '60px',
                                    // border: '10px solid #0e5ed7'
                                }}
                                >
                                    <Grid container md={12} spacing={12}>
                                        <Grid item md={6} display='flex' alignItems='center'>
                                            <Box bgcolor='#dce9f7' borderRadius='60px' overflow='hidden'>
                                                <img alt='Xtrasenses ai' src="/images/XtraSenses-ai.jpg"
                                                    css={{
                                                        maxWidth: '100%',
                                                        height: 'auto',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item md={6}>
                                            <Box py={4} sx={{
                                                borderRadius: '60px',
                                                // border: '10px solid #0e5ed7'
                                            }}
                                                component={motion.div}
                                                initial={{
                                                    x: 500,

                                                }}
                                                animate={{
                                                    x: 0
                                                }}
                                            >
                                                <Box pb={3} display='flex'>
                                                    <Typography variant="h4" mr={4}>04.</Typography>
                                                    <Box>
                                                        <Typography pb={2} variant="h5" sx={{ fontWeight: '600' }}>XtraSenses AI - To bring out the best in every child</Typography>
                                                        <Typography color='GrayText' >
                                                            XtraSenses-AI system keeps a personalized watch on the health of every child participating in the program.                                            </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>


                            <Grid item md={12}>
                                <Box bgcolor='#0e5ed7' py={4} px={4} sx={{
                                    borderRadius: '60px',
                                    // border: '10px solid #0e5ed7'
                                }}>
                                    <Grid container md={12} spacing={12}>
                                        <Grid item md={6}>
                                            <Box sx={{
                                                borderRadius: '60px',
                                                border: '10px solid #0e5ed7'
                                            }}
                                                component={motion.div}
                                                initial={{
                                                    x: -500,

                                                }}
                                                animate={{
                                                    x: 0
                                                }}
                                            >
                                                <Box pb={3} display='flex' color='white'>
                                                    <Typography variant="h4" mr={4}>05.</Typography>
                                                    <Box >
                                                        <Typography pb={2} variant="h5" sx={{ fontWeight: '600' }}>Joyful, Gamified approach towards activities</Typography>
                                                        <Typography variant="body1" color='#dde8f9' >
                                                            XtraSenses program activities are designed with fun-filled approach to keep the kids motivated with interesting games, leaderboards and healthy competitions.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item md={6} display='flex' alignItems='center'>
                                            <Box bgcolor='#dce9f7' borderRadius='60px' overflow='hidden'>
                                                <img alt='gamified approach' src="/images/Joyous-activity.png"
                                                    css={{
                                                        maxWidth: '100%',
                                                        height: 'auto',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </Box>

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>



                            <Grid item md={12}>
                                <Box bgcolor='#f5f5f5' py={4} px={4} sx={{
                                    borderRadius: '60px',
                                    // border: '10px solid #0e5ed7'
                                }}
                                >
                                    <Grid container md={12} spacing={12}>
                                        <Grid item md={6}>
                                            <Box bgcolor='#dce9f7' borderRadius='60px' overflow='hidden'>
                                                <img alt='In School' src="/images/School-delivered.png"
                                                    css={{
                                                        maxWidth: '100%',
                                                        height: 'auto',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </Box>

                                        </Grid>

                                        <Grid item md={6}>
                                            <Box py={4} sx={{
                                                borderRadius: '60px',
                                                // border: '10px solid #0e5ed7'
                                            }}
                                                component={motion.div}
                                                initial={{
                                                    x: 500,

                                                }}
                                                animate={{
                                                    x: 0
                                                }}
                                            >
                                                <Box pb={3} display='flex'>
                                                    <Typography variant="h4" mr={4}>06.</Typography>
                                                    <Box>
                                                        <Typography pb={2} variant="h5" sx={{ fontWeight: '600' }}>Delivered at school - hassle free and effective</Typography>
                                                        <Typography color='GrayText'>
                                                            Our programs minimize the time and effort required for achieving fitness and weightloss goals.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>


                        </Grid>



                    </Box>
                </Container>
            </Box>

            {/*------------ gap between sections ---------- */}
            <Box py={4}></Box>
            {/*------------ gap between sections ---------- */}

            <Box
                bgcolor='#040404'
            // sx={{
            //     background: 'linear-gradient(180deg, hsla(229, 100%, 66%, 1) 0%, hsla(0, 0%, 100%, 1) 100%)',
            // }}
            >
                <Container>
                    <Box
                        display='flex'
                        justifyContent='center'
                        sx={{
                            // backgroundColor: '#5070FF',
                            minHeight: '60vh',
                            padding: '40px'
                        }}
                    >
                        <Typography
                            pt={4}
                            variant="h4"
                            color='white'
                            sx={{

                                fontWeight: '600',
                                // lineHeight: '1.4em'
                            }}
                            mb={4}
                        >
                            Find the right program for your kid
                        </Typography>
                    </Box>
                </Container>
            </Box>

            {/*------------ gap between sections ---------- */}
            <Box py={4}></Box>
            {/*------------ gap between sections ---------- */}

            <Box
            // bgcolor='#5070FF'
            >
                <Container>
                    <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        sx={{
                            // backgroundColor: '#5070FF',
                            minHeight: '60vh',
                            padding: '40px'
                        }}
                    >
                        <Typography
                            pt={4}
                            variant="h4"
                            // color='white'
                            sx={{

                                fontWeight: '600',
                                // lineHeight: '1.4em'
                            }}
                            mb={4}
                        >
                            What doctors say about XtraSenses
                        </Typography>

                        <Box width='100%'>
                            <Grid direction='row' container spacing={4} mt={2}>
                                <Grid md={4} item>
                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        flexGrow='1'
                                        height='300px'
                                        bgcolor='#f5f5f5'
                                        px={2}
                                        py={2}
                                        sx={{

                                            borderRadius: '20px',
                                            overflow: 'hidden'
                                        }}

                                    >
                                        <FormatQuoteIcon css={css({
                                            transform: 'rotate(180deg)',
                                            fontSize: '3em',
                                            color: '#ff5e20'
                                        })} />
                                        <Typography variant="body1" color='GrayText' sx={{ fontWeight: '500' }}>
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus iste ea quaerat impedit ab facere mollitia deleniti, repellendus magnam quis? Explicabo, a earum officiis aliquid placeat beatae laboriosam ex consequuntur.
                                        </Typography>
                                        <Box height='100%'></Box>
                                        <Divider sx={{ background: '#ff5e20' }}></Divider>
                                        <Box display='flex' alignItems='center' justifyContent='flex-end' mt={1}>

                                            <Box px={2}>
                                                <Typography variant="body2" sx={{ fontWeight: '700' }} >Dr. Madhusudan</Typography>
                                                <Typography color='grey' variant="caption" sx={{}}>
                                                    MBBS, FRCS,<br />Stanley Hospital
                                                </Typography>
                                            </Box>
                                            <Avatar
                                                alt="Dr Madhusudan"
                                                src="/images/doctor_01.jpg"
                                                sx={{
                                                    height: '50px',
                                                    width: '50px'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>


                                <Grid md={4} item>
                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        flexGrow='1'
                                        height='300px'
                                        bgcolor='#f5f5f5'
                                        px={2}
                                        py={2}
                                        sx={{

                                            borderRadius: '20px',
                                            overflow: 'hidden'
                                        }}

                                    >
                                        <FormatQuoteIcon css={css({
                                            transform: 'rotate(180deg)',
                                            fontSize: '3em',
                                            color: '#ff5e20'
                                        })} />
                                        <Typography variant="body1" color='GrayText' sx={{ fontWeight: '500' }}>
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus iste ea quaerat impedit ab facere mollitia deleniti, repellendus magnam quis? Explicabo, a earum officiis aliquid placeat beatae laboriosam ex consequuntur.
                                        </Typography>
                                        <Box height='100%'></Box>
                                        <Divider sx={{ background: '#ff5e20' }}></Divider>
                                        <Box display='flex' alignItems='center' justifyContent='flex-end' mt={1}>

                                            <Box px={2}>
                                                <Typography variant="body2" sx={{ fontWeight: '700' }} >Dr. Madhusudan</Typography>
                                                <Typography color='grey' variant="caption" sx={{}}>
                                                    MBBS, FRCS,<br />Stanley Hospital
                                                </Typography>
                                            </Box>
                                            <Avatar
                                                alt="Dr Madhusudan"
                                                src="/images/doctor_01.jpg"
                                                sx={{
                                                    height: '50px',
                                                    width: '50px'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>


                                <Grid md={4} item>
                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        flexGrow='1'
                                        height='300px'
                                        bgcolor='#f5f5f5'
                                        px={2}
                                        py={2}
                                        sx={{

                                            borderRadius: '20px',
                                            overflow: 'hidden'
                                        }}

                                    >
                                        <FormatQuoteIcon css={css({
                                            transform: 'rotate(180deg)',
                                            fontSize: '3em',
                                            color: '#ff5e20'
                                        })} />
                                        <Typography variant="body1" color='GrayText' sx={{ fontWeight: '500' }}>
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus iste ea quaerat impedit ab facere mollitia deleniti, repellendus magnam quis? Explicabo, a earum officiis aliquid placeat beatae laboriosam ex consequuntur.
                                        </Typography>
                                        <Box height='100%'></Box>
                                        <Divider sx={{ background: '#ff5e20' }}></Divider>
                                        <Box display='flex' alignItems='center' justifyContent='flex-end' mt={1}>

                                            <Box px={2}>
                                                <Typography variant="body2" sx={{ fontWeight: '700' }} >Dr. Madhusudan</Typography>
                                                <Typography color='grey' variant="caption" sx={{}}>
                                                    MBBS, FRCS,<br />Stanley Hospital
                                                </Typography>
                                            </Box>
                                            <Avatar
                                                alt="Dr Madhusudan"
                                                src="/images/doctor_01.jpg"
                                                sx={{
                                                    height: '50px',
                                                    width: '50px'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid md={4} item>
                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        flexGrow='1'
                                        height='300px'
                                        bgcolor='#f5f5f5'
                                        px={2}
                                        py={2}
                                        sx={{

                                            borderRadius: '20px',
                                            overflow: 'hidden'
                                        }}

                                    >
                                        <FormatQuoteIcon css={css({
                                            transform: 'rotate(180deg)',
                                            fontSize: '3em',
                                            color: '#ff5e20'
                                        })} />
                                        <Typography variant="body1" color='GrayText' sx={{ fontWeight: '500' }}>
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus iste ea quaerat impedit ab facere mollitia deleniti, repellendus magnam quis? Explicabo, a earum officiis aliquid placeat beatae laboriosam ex consequuntur.
                                        </Typography>
                                        <Box height='100%'></Box>
                                        <Divider sx={{ background: '#ff5e20' }}></Divider>
                                        <Box display='flex' alignItems='center' justifyContent='flex-end' mt={1}>

                                            <Box px={2}>
                                                <Typography variant="body2" sx={{ fontWeight: '700' }} >Dr. Madhusudan</Typography>
                                                <Typography color='grey' variant="caption" sx={{}}>
                                                    MBBS, FRCS,<br />Stanley Hospital
                                                </Typography>
                                            </Box>
                                            <Avatar
                                                alt="Dr Madhusudan"
                                                src="/images/doctor_01.jpg"
                                                sx={{
                                                    height: '50px',
                                                    width: '50px'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid md={4} item>
                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        flexGrow='1'
                                        height='300px'
                                        bgcolor='#f5f5f5'
                                        px={2}
                                        py={2}
                                        sx={{

                                            borderRadius: '20px',
                                            overflow: 'hidden'
                                        }}

                                    >
                                        <FormatQuoteIcon css={css({
                                            transform: 'rotate(180deg)',
                                            fontSize: '3em',
                                            color: '#ff5e20'
                                        })} />
                                        <Typography variant="body1" color='GrayText' sx={{ fontWeight: '500' }}>
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus iste ea quaerat impedit ab facere mollitia deleniti, repellendus magnam quis? Explicabo, a earum officiis aliquid placeat beatae laboriosam ex consequuntur.
                                        </Typography>
                                        <Box height='100%'></Box>
                                        <Divider sx={{ background: '#ff5e20' }}></Divider>
                                        <Box display='flex' alignItems='center' justifyContent='flex-end' mt={1}>

                                            <Box px={2}>
                                                <Typography variant="body2" sx={{ fontWeight: '700' }} >Dr. Madhusudan</Typography>
                                                <Typography color='grey' variant="caption" sx={{}}>
                                                    MBBS, FRCS,<br />Stanley Hospital
                                                </Typography>
                                            </Box>
                                            <Avatar
                                                alt="Dr Madhusudan"
                                                src="/images/doctor_01.jpg"
                                                sx={{
                                                    height: '50px',
                                                    width: '50px'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid md={4} item>
                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        flexGrow='1'
                                        height='300px'
                                        bgcolor='#f5f5f5'
                                        px={2}
                                        py={2}
                                        sx={{

                                            borderRadius: '20px',
                                            overflow: 'hidden'
                                        }}

                                    >
                                        <FormatQuoteIcon css={css({
                                            transform: 'rotate(180deg)',
                                            fontSize: '3em',
                                            color: '#ff5e20'
                                        })} />
                                        <Typography variant="body1" color='GrayText' sx={{ fontWeight: '500' }}>
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus iste ea quaerat impedit ab facere mollitia deleniti, repellendus magnam quis? Explicabo, a earum officiis aliquid placeat beatae laboriosam ex consequuntur.
                                        </Typography>
                                        <Box height='100%'></Box>
                                        <Divider sx={{ background: '#ff5e20' }}></Divider>
                                        <Box display='flex' alignItems='center' justifyContent='flex-end' mt={1}>

                                            <Box px={2}>
                                                <Typography variant="body2" sx={{ fontWeight: '700' }} >Dr. Madhusudan</Typography>
                                                <Typography color='grey' variant="caption" sx={{}}>
                                                    MBBS, FRCS,<br />Stanley Hospital
                                                </Typography>
                                            </Box>
                                            <Avatar
                                                alt="Dr Madhusudan"
                                                src="/images/doctor_01.jpg"
                                                sx={{
                                                    height: '50px',
                                                    width: '50px'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>

















                            </Grid>
                        </Box>


                    </Box>
                </Container>
            </Box >

            {/*------------ gap between sections ---------- */}
            < Box py={4} ></Box >
            {/*------------ gap between sections ---------- */}

            <Box bgcolor='#5070FF'>
                <Container>
                    <Box

                        width='100%'
                        justifyContent='center'
                        sx={{
                            backgroundColor: '#5070FF',
                            minHeight: '60vh',
                            padding: '40px'
                        }}
                    >
                    </Box>
                </Container>
            </Box >

        </div >
    );
}

export default Home;